import React, { useEffect, useState } from 'react'
import Nav from '../components/nav'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../components/apiRoot";

function CreateCoupon() {
    // history 
    const navigate = useNavigate()

    // redirect if user is not logged in 
    useEffect(() => {
        if (localStorage.getItem('ks-admin-token') === null) {
            navigate('/')
        }
    }, [])

    const [name, setName] = useState("")
    const [discount, setDiscount] = useState("")

    const [loading, setLoading] = useState(false)
    const CreateCoupon = () => {
        setLoading(true)
        axios.post(`${API.API_ROOT}/coupon/v1/createAcoupon`,
            { couponCode: name, couponDiscount: discount },
            { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('ks-admin-token')}` } })
            .then(response => {
                console.log(response)
                navigate("/coupons")
            }).catch(error => {
                console.error(error)
            })
    }

    return (
        <div className="container-fluid">
            {/* navigation */}
            <Nav />
            {/* login div  */}
            <div className="container create">
                <h1>Create Coupon</h1>
                <div className="row justify-content-between mb-5">
                    <div className="col-5">
                        <label htmlFor="name">Coupon Code Name</label>
                        <input type="text" id="name" placeholder="Enter coupon Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="col-5">
                        <label htmlFor="amount">Coupon discount</label>
                        <input type="number" id="amount" placeholder="Enter Percentage Here" value={discount} onChange={(e) => setDiscount(e.target.value)} />
                    </div>
                </div>
                <button onClick={CreateCoupon}>{loading ? <>Loading...  <i className="fa-solid fa-spinner fa-spin" /></> : "Create Coupon"}</button>
            </div>
        </div>
    )
}

export default CreateCoupon
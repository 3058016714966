import React, { useState, useEffect } from 'react'
import Nav from '../components/nav'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../components/apiRoot";
import Modal from 'react-modal'
Modal.setAppElement('#root')

function Coupons() {
    // history 
    const navigate = useNavigate()

    // redirect if user is not logged in 
    useEffect(() => {
        if (localStorage.getItem('ks-admin-token') === null) {
            navigate('/')
        }
    }, [])

    const [coupons, setCoupons] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchCoupons = () => {
        axios.get(`${API.API_ROOT}/coupon/v1/getAllCoupon`,
            { headers: { 'content-type': 'application/json' } })
            .then(response => {
                // console.log(response)
                setCoupons(response.data.data)
                setLoading(false)
            }).catch(error => {
                console.error(error)
            })
    }

    useEffect(() => {
        fetchCoupons()
    }, [])

    return (
        <div className="container-fluid">
            {/* navigation */}
            <Nav />
            {/* login div  */}
            <div className="container coupons">
                <button id="create" onClick={() => navigate("/create-coupon")}>Create Coupon</button>
                <h1>Coupon</h1>
                {loading ?
                    <h3>fetching coupons.....</h3> :
                    coupons.map((coupon, index) => {
                        return <Coupon coupon={coupon} key={index} />
                    })
                }
            </div>
        </div>
    )
}

function Coupon({ coupon }) {
    // history 
    const navigate = useNavigate()

    const [deleteModal, setDeleteModal] = useState(false)

    const [deleteLoading, setDeleteLoading] = useState(false)
    const deleteCoupon = () => {
        setDeleteLoading(true)
        axios.delete(`${API.API_ROOT}/coupon/v1/${coupon._id}/deleteCoupon`,
            { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('ks-admin-token')}` } })
            .then(response => {
                console.log(response)
                window.location.reload()
            }).catch(error => {
                console.error(error)
            })
    }

    return (
        <div className="coupon">
            <div className="row align-items-center">
                <div className="col-4">
                    <h3>Coupon Code Name</h3>
                    <p className="mb-0">{coupon.couponCode}</p>
                </div>
                <div className="col-3">
                    <h3>Coupon Discount</h3>
                    <p className="mb-0">{coupon.couponDiscount}%</p>
                </div>
                <div className="col-3">
                    <button className="edit" onClick={() => navigate(`/edit-coupon/${coupon._id}`)} >Edit</button>
                </div>
                <div className="col-2">
                    <button className="delete" onClick={() => setDeleteModal(true)}><i className="fa-solid fa-trash-can" />Delete</button>
                </div>
            </div>

            {/* delete modal  */}
            <Modal isOpen={deleteModal} onRequestClose={() => setDeleteModal(false)} id="delete-modal" >
                <h1>Delete Coupon</h1>
                <p>Are you sure you want to delete this coupon?</p>
                <div className="d-flex justify-content-between">
                    <button id='cancel' onClick={() => setDeleteModal(false)}>Cancel</button>
                    <button id='delete' onClick={deleteCoupon}>{deleteLoading ? <>Deleting...  <i className="fa-solid fa-spinner fa-spin" /></> : "Delete Coupon"}</button>
                </div>
            </Modal>
        </div>
    )
}

export default Coupons
import React, { useState, useEffect } from 'react'
import Nav from '../components/nav';
import axios from "axios";
import { API } from "../components/apiRoot";
import { useNavigate } from "react-router-dom";

function Login() {
    // history 
    const navigate = useNavigate()

    // redirect if user is not logged in 
    useEffect(() => {
        if (localStorage.getItem('ks-admin-token') !== null) {
            navigate('/coupons')
        }
    }, [])

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const login = () => {
        setError(null)
        setLoading(true)
        axios.post(`${API.API_ROOT}/user/v1/signin`, { username: email, password: password })
            .then(response => {
                console.log(response)
                localStorage.setItem('ks-admin-token', response.data.data.token)
                navigate("/coupons")
                setLoading(false)
            }).catch(error => {
                console.log(error.response.status)
                setLoading(false)
            })
    }

    return (
        <div className="container-fluid">
            {/* navigation */}
            <Nav />
            {/* login div  */}
            <div className="login">
                <h1>Admin Login</h1>
                <label htmlFor="email">Email Address</label>
                <input type="text" id="email" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                <label htmlFor="Password">Password</label>
                <input type="password" id="Password" placeholder="***************" value={password} onChange={(e) => setPassword(e.target.value)} />
                <div className="d-flex align-items-center">
                    <input type="checkbox" id="me" />
                    <label htmlFor="me" className="mb-0">Remember Me</label>
                </div>
                <p>{error}</p>
                <button onClick={login}>{loading ? <>Loading...  <i className="fa-solid fa-spinner fa-spin" /></> : "Login"}</button>
            </div>
        </div>
    )
}

export default Login
import React from 'react'
import { Link } from "react-router-dom";

function Nav() {
    return (
        <div className="nav-container">
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to='/'><img src="/img/logo.png" className="logo" /></Link>
                </div>
            </nav>
        </div>
    )
}

export default Nav
import React, { useState, useEffect } from 'react'
import Nav from '../components/nav'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API } from "../components/apiRoot";

function EditCoupon() {
    // history 
    const navigate = useNavigate()

    // redirect if user is not logged in 
    useEffect(() => {
        if (localStorage.getItem('ks-admin-token') === null) {
            navigate('/')
        }
    }, [])

    // params 
    const { id } = useParams()

    const [name, setName] = useState("")
    const [amount, setAmount] = useState(0)

    const [pageLoader, setPageLoader] = useState(true)
    const getSingleCoupon = () => {
        axios.get(`${API.API_ROOT}/coupon/v1/${id}/getSingleCoupon`,
            { headers: { 'content-type': 'application/json' } })
            .then(response => {
                setName(response.data.data.couponCode)
                setAmount(response.data.data.couponDiscount)
                setPageLoader(false)
            }).catch(error => {
                console.error(error)
            })
    }
    useEffect(() => {
        if (id && id !== '') getSingleCoupon()
    }, [id])

    const [updateLoading, setUpdateLoading] = useState(false)
    const updateCoupon = () => {
        setUpdateLoading(true)
        // console.log(localStorage.getItem("ks-admin-token"))
        axios.patch(`${API.API_ROOT}/coupon/v1/${id}/editCoupon`,
            { couponCode: name, couponDiscount: amount },
            { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('ks-admin-token')}` } })
            .then(response => {
                console.log(response)
                navigate("/coupons")
            }).catch(error => {
                setUpdateLoading(false)
                console.error(error)
            })
    }

    return (
        <div className="container-fluid">
            {/* navigation */}
            <Nav />
            {/* login div  */}
            <div className="container create">
                {pageLoader ?
                    <h3>fetching coupon.....</h3> :
                    <>
                        <h1>Edit Coupon</h1>
                        <div className="row justify-content-between mb-5">
                            <div className="col-5">
                                <label htmlFor="name">Coupon Code Name</label>
                                <input type="text" id="name" placeholder="Enter coupon Name" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="col-5">
                                <label htmlFor="amount">Coupon discount</label>
                                <input type="number" id="amount" placeholder="Enter coupon Name" value={amount} onChange={(e) => setAmount(e.target.value)} />
                            </div>
                        </div>
                        <button onClick={updateCoupon}>{updateLoading ? <>Loading...  <i className="fa-solid fa-spinner fa-spin" /></> : "Update Coupon"}</button>
                    </>
                }
            </div>
        </div>
    )
}

export default EditCoupon
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './screens/Login';
import Coupons from './screens/Coupons';
import CreateCoupon from './screens/CreateCoupon';
import EditCoupon from './screens/EditCoupon';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/coupons" element={<Coupons />} />
        <Route exact path="/create-coupon" element={<CreateCoupon />} />
        <Route exact path="/edit-coupon/:id" element={<EditCoupon />} />
      </Routes>
    </Router>
  );
}

export default App;
